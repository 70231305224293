<template>
  <div v-if="mode === 'horizontal'" class="logo logo--horizontal">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="90px"
      height="40px"
      viewBox="0 0 311.57 137.29"
      style="overflow:visible;enable-background:new 0 0 311.57 137.29;"
      xml:space="preserve"
    >
      <g id="XMLID_12_">
        <g id="XMLID_14_">
          <path
            id="XMLID_22_"
            :fill="color"
            d="M44.38,54.18h0.15c0.93,0,1.86-0.1,2.81-0.31c0.95-0.2,1.81-0.56,2.58-1.08
			c0.77-0.51,1.4-1.18,1.89-2c0.49-0.82,0.73-1.82,0.73-3.01c0-1.28-0.32-2.33-0.96-3.12c-0.64-0.8-1.45-1.4-2.43-1.81
			c-0.98-0.41-2.03-0.69-3.16-0.85c-1.13-0.15-2.18-0.26-3.16-0.26l-18.66-0.03v12.44h20.21V54.18z"
          />
          <path
            id="XMLID_21_"
            :fill="color"
            d="M42.41,31.59h0.04c0.93,0,1.84-0.1,2.74-0.31c0.9-0.2,1.71-0.54,2.43-1
			c0.72-0.46,1.3-1.08,1.73-1.85c0.44-0.77,0.66-1.7,0.66-2.78c0-1.13-0.24-2.07-0.73-2.81c-0.49-0.74-1.12-1.32-1.89-1.73
			c-0.77-0.41-1.65-0.71-2.62-0.89c-0.98-0.18-1.93-0.22-2.85-0.22l-17.74-0.01v11.58h18.23V31.59z"
          />
          <path
            id="XMLID_15_"
            :fill="color"
            d="M0,0v137.29h137.29V0H0z M12.51,9.77h1.21h10.46h9.56h5.65h2.67c2.36,0,4.74,0.17,7.13,0.5
			c2.39,0.34,4.54,1,6.44,2c1.9,1,3.44,2.4,4.63,4.2c1.18,1.8,1.77,4.19,1.77,7.17c0,3.08-0.86,5.64-2.58,7.67
			c-1.24,1.47-2.13,2.91-6.82,4.36v0.15c1.8,0.26,3.43,0.76,4.9,1.5c1.47,0.75,2.72,1.7,3.78,2.85c1.05,1.16,1.86,2.51,2.43,4.05
			c0.57,1.54,0.85,3.19,0.85,4.93c0,2.88-0.62,5.28-1.85,7.21c-1.23,1.93-2.83,3.48-4.78,4.66c-1.95,1.18-4.15,2.03-6.59,2.54
			c-2.34,0.49-4.66,0.73-6.97,0.75v0.02h-0.32H33.74h-9.56h-9.88h-1.79V9.77z M38.83,127.26c-14.97,0-27.11-12.14-27.11-27.11
			c0-14.97,12.14-27.11,27.11-27.11c14.97,0,27.11,12.14,27.11,27.11C65.94,115.12,53.8,127.26,38.83,127.26z M125.13,127.78h-15.76
			L85.49,88.84h-0.15v38.94H73.29v-54.7h16.38l23.26,38.01h0.15V73.08h12.05V127.78z M125.13,21.43H84.82v9.94h26.1v11.64h-26.1
			v9.64h40.32v11.64H73.18V52.65h0v-9.64h0V31.37h0v-9.94h0V9.79h51.96V21.43z"
          />
        </g>

        <ellipse
          id="XMLID_13_"
          transform="matrix(0.3827 -0.9239 0.9239 0.3827 -68.5613 97.6771)"
          :fill="color"
          cx="38.81"
          cy="100.14"
          rx="15.03"
          ry="15.03"
        />
      </g>
      <g>
        <path
          id="XMLID_10_"
          :fill="color"
          d="M176.85,54.34h-13.51v28.73c0,1.82,0.17,3.33,0.51,4.53c0.34,1.19,0.83,2.12,1.48,2.78
		c0.65,0.67,1.42,1.12,2.31,1.37c0.88,0.25,1.84,0.37,2.87,0.37c0.95,0,1.96-0.14,3.02-0.42c1.06-0.28,2.07-0.67,3.02-1.16
		l0.31,6.21c-1.23,0.49-2.51,0.86-3.84,1.1c-1.33,0.24-2.78,0.37-4.35,0.37c-1.3,0-2.67-0.18-4.1-0.53c-1.43-0.35-2.77-1.01-3.99-2
		c-1.23-0.99-2.24-2.32-3.02-4c-0.78-1.69-1.17-3.9-1.17-6.63V54.34h-9.93v-6h9.93V34.44h6.96v13.89h13.51V54.34z"
        />
        <path
          id="XMLID_8_"
          :fill="color"
          d="M181.82,54.02c-0.07-2.11-0.17-4-0.31-5.69h6.65c0.14,1.34,0.24,2.81,0.31,4.42
		c0.07,1.62,0.11,2.95,0.11,4h0.2c1.37-2.88,3.42-5.21,6.14-7c2.73-1.79,5.81-2.68,9.21-2.68c0.89,0,1.69,0.03,2.41,0.1
		c0.72,0.07,1.45,0.21,2.2,0.42l-0.92,6.84c-0.41-0.14-1.09-0.28-2.05-0.42c-0.96-0.15-1.91-0.21-2.87-0.21
		c-1.84,0-3.62,0.35-5.32,1.05c-1.71,0.7-3.19,1.8-4.46,3.31c-1.26,1.51-2.28,3.43-3.07,5.77c-0.78,2.35-1.17,5.17-1.17,8.45v25.2
		h-6.96V59.18C181.92,57.84,181.89,56.13,181.82,54.02z"
        />
        <path
          id="XMLID_6_"
          :fill="color"
          d="M219.41,97.59h-6.96V48.33h6.96V97.59z"
        />
        <path
          id="XMLID_3_"
          :fill="color"
          d="M232.23,122.85h-6.96V48.33h6.96v8.53h0.3c1.98-3.23,4.62-5.67,7.94-7.31
		c3.31-1.65,6.66-2.48,10.08-2.48c3.75,0,7.15,0.67,10.19,2c3.03,1.34,5.63,3.16,7.78,5.47c2.15,2.32,3.82,5.05,5.02,8.21
		c1.19,3.15,1.79,6.56,1.79,10.21c0,3.65-0.6,7.05-1.79,10.21c-1.2,3.15-2.87,5.89-5.02,8.21c-2.15,2.31-4.75,4.14-7.78,5.47
		c-3.04,1.34-6.44,2-10.19,2c-3.42,0-6.77-0.82-10.08-2.47c-3.32-1.65-5.96-4.02-7.94-7.11h-0.3V122.85z M267.95,72.97
		c0-2.74-0.41-5.32-1.22-7.74c-0.82-2.42-2-4.52-3.53-6.31c-1.54-1.79-3.43-3.21-5.68-4.27c-2.26-1.04-4.82-1.58-7.68-1.58
		c-2.66,0-5.12,0.51-7.37,1.52c-2.25,1.02-4.2,2.42-5.83,4.21c-1.64,1.79-2.92,3.9-3.84,6.31c-0.92,2.42-1.38,5.04-1.38,7.85
		c0,2.8,0.46,5.41,1.38,7.84s2.2,4.51,3.84,6.26c1.63,1.76,3.58,3.14,5.83,4.16c2.26,1.01,4.72,1.52,7.37,1.52
		c2.87,0,5.42-0.51,7.68-1.52c2.25-1.02,4.14-2.42,5.68-4.21c1.53-1.79,2.71-3.9,3.53-6.31C267.54,78.28,267.95,75.7,267.95,72.97z"
        />
        <path
          id="XMLID_1_"
          :fill="color"
          d="M305.43,60.02c-1.02-2.03-2.58-3.7-4.66-4.99c-2.08-1.3-4.46-1.95-7.11-1.95
		c-1.23,0-2.46,0.15-3.69,0.42c-1.22,0.28-2.32,0.74-3.27,1.37c-0.96,0.63-1.72,1.44-2.31,2.42c-0.57,0.99-0.87,2.14-0.87,3.47
		c0,2.32,0.92,4.09,2.77,5.32s4.74,2.26,8.7,3.1c5.67,1.2,9.85,2.93,12.55,5.21c2.69,2.29,4.04,5.35,4.04,9.22
		c0,2.8-0.53,5.17-1.59,7.1c-1.06,1.94-2.44,3.51-4.14,4.74c-1.71,1.23-3.63,2.11-5.78,2.63c-2.16,0.53-4.32,0.79-6.51,0.79
		c-3.62,0-7.13-0.77-10.54-2.31c-3.42-1.54-6.21-3.97-8.4-7.26l5.52-4.21c1.23,2.18,3.04,4,5.43,5.47c2.38,1.47,5.02,2.21,7.88,2.21
		c1.5,0,2.93-0.15,4.3-0.42c1.37-0.28,2.56-0.77,3.58-1.47c1.02-0.7,1.84-1.6,2.46-2.69s0.92-2.43,0.92-4.05
		c0-2.6-1.22-4.54-3.64-5.84c-2.42-1.29-5.92-2.51-10.49-3.63c-1.43-0.35-2.96-0.79-4.56-1.31c-1.61-0.53-3.09-1.3-4.45-2.32
		c-1.37-1.01-2.5-2.3-3.38-3.84c-0.89-1.54-1.33-3.48-1.33-5.79c0-2.53,0.47-4.69,1.43-6.48c0.96-1.79,2.24-3.26,3.84-4.42
		s3.43-2.01,5.48-2.58c2.04-0.56,4.16-0.84,6.34-0.84c3.35,0,6.59,0.77,9.73,2.31c3.13,1.54,5.49,3.72,7.06,6.53L305.43,60.02z"
        />
      </g>
    </svg>
  </div>
  <div v-else class="logo logo--vertical">
    <!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In  -->
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="137.7px"
      height="213.5px"
      viewBox="0 0 137.7 213.5"
      style="overflow:visible;enable-background:new 0 0 137.7 213.5;"
      xml:space="preserve"
    >
      <defs />
      <g id="XMLID_1_">
        <g id="XMLID_14_">
          <path
            id="XMLID_22_"
            :fill="color"
            d="M44.8,54.2h0.1c0.9,0,1.9-0.1,2.8-0.3c1-0.2,1.8-0.6,2.6-1.1c0.8-0.5,1.4-1.2,1.9-2
			c0.5-0.8,0.7-1.8,0.7-3c0-1.3-0.3-2.3-1-3.1c-0.6-0.8-1.5-1.4-2.4-1.8c-1-0.4-2-0.7-3.2-0.8c-1.1-0.2-2.2-0.3-3.2-0.3l-18.7,0
			v12.4L44.8,54.2L44.8,54.2z"
          />
          <path
            id="XMLID_21_"
            :fill="color"
            d="M42.8,31.6L42.8,31.6c1,0,1.9-0.1,2.8-0.3c0.9-0.2,1.7-0.5,2.4-1c0.7-0.5,1.3-1.1,1.7-1.9
			c0.4-0.8,0.7-1.7,0.7-2.8c0-1.1-0.2-2.1-0.7-2.8c-0.5-0.7-1.1-1.3-1.9-1.7c-0.8-0.4-1.6-0.7-2.6-0.9c-1-0.2-1.9-0.2-2.9-0.2
			l-17.7,0v11.6L42.8,31.6L42.8,31.6z"
          />
          <path
            id="XMLID_15_"
            :fill="color"
            d="M0.4,0v137.3h137.3V0H0.4z M12.9,9.8h1.2h10.5h9.6h5.7h2.7c2.4,0,4.7,0.2,7.1,0.5
			c2.4,0.3,4.5,1,6.4,2c1.9,1,3.4,2.4,4.6,4.2c1.2,1.8,1.8,4.2,1.8,7.2c0,3.1-0.9,5.6-2.6,7.7c-1.2,1.5-2.1,2.9-6.8,4.4v0.2
			c1.8,0.3,3.4,0.8,4.9,1.5c1.5,0.7,2.7,1.7,3.8,2.9c1.1,1.2,1.9,2.5,2.4,4c0.6,1.5,0.8,3.2,0.8,4.9c0,2.9-0.6,5.3-1.9,7.2
			c-1.2,1.9-2.8,3.5-4.8,4.7c-2,1.2-4.2,2-6.6,2.5c-2.3,0.5-4.7,0.7-7,0.8v0h-0.3H34.2h-9.6h-9.9h-1.8V9.8z M39.3,127.3
			c-15,0-27.1-12.1-27.1-27.1c0-15,12.1-27.1,27.1-27.1c15,0,27.1,12.1,27.1,27.1C66.4,115.1,54.2,127.3,39.3,127.3z M125.5,127.8
			h-15.8L85.9,88.8h-0.2v38.9H73.7V73.1h16.4l23.3,38h0.2v-38h12.1V127.8z M125.6,21.4H85.2v9.9h26.1V43H85.2v9.6h40.3v11.6h-52
			V52.6h0V43h0V31.4h0v-9.9h0V9.8h52V21.4z"
          />
        </g>

        <ellipse
          id="XMLID_13_"
          transform="matrix(0.3827 -0.9239 0.9239 0.3827 -68.3014 98.0662)"
          :fill="color"
          cx="39.2"
          cy="100.1"
          rx="15"
          ry="15"
        />
        <path
          id="XMLID_11_"
          :fill="color"
          d="M25.4,156.4H14.1v24c0,1.5,0.1,2.8,0.4,3.8c0.3,1,0.7,1.8,1.2,2.3c0.5,0.6,1.2,0.9,1.9,1.1
		c0.7,0.2,1.5,0.3,2.4,0.3c0.8,0,1.6-0.1,2.5-0.4c0.9-0.2,1.7-0.6,2.5-1l0.3,5.2c-1,0.4-2.1,0.7-3.2,0.9c-1.1,0.2-2.3,0.3-3.6,0.3
		c-1.1,0-2.2-0.2-3.4-0.4c-1.2-0.3-2.3-0.8-3.3-1.7c-1-0.8-1.9-1.9-2.5-3.3c-0.7-1.4-1-3.3-1-5.5v-25.6H0v-5h8.3v-11.6h5.8v11.6
		h11.3V156.4z"
        />
        <path
          id="XMLID_9_"
          :fill="color"
          d="M29.5,156.1c-0.1-1.8-0.1-3.3-0.3-4.7h5.5c0.1,1.1,0.2,2.3,0.3,3.7c0.1,1.4,0.1,2.5,0.1,3.3h0.2
		c1.1-2.4,2.8-4.3,5.1-5.8c2.3-1.5,4.8-2.2,7.7-2.2c0.7,0,1.4,0,2,0.1c0.6,0.1,1.2,0.2,1.8,0.4l-0.8,5.7c-0.3-0.1-0.9-0.2-1.7-0.4
		c-0.8-0.1-1.6-0.2-2.4-0.2c-1.5,0-3,0.3-4.4,0.9c-1.4,0.6-2.7,1.5-3.7,2.8c-1,1.3-1.9,2.9-2.6,4.8c-0.7,2-1,4.3-1,7.1v21h-5.8v-32
		C29.6,159.3,29.6,157.9,29.5,156.1z"
        />
        <path id="XMLID_7_" :fill="color" d="M60.9,192.4H55v-41.1h5.8V192.4z" />
        <path
          id="XMLID_4_"
          :fill="color"
          d="M71.5,213.5h-5.8v-62.1h5.8v7.1h0.3c1.7-2.7,3.9-4.7,6.6-6.1c2.8-1.4,5.6-2.1,8.4-2.1
		c3.1,0,6,0.6,8.5,1.7c2.5,1.1,4.7,2.6,6.5,4.6c1.8,1.9,3.2,4.2,4.2,6.9c1,2.6,1.5,5.5,1.5,8.5s-0.5,5.9-1.5,8.5
		c-1,2.6-2.4,4.9-4.2,6.8c-1.8,1.9-4,3.5-6.5,4.6c-2.5,1.1-5.4,1.7-8.5,1.7c-2.8,0-5.6-0.7-8.4-2.1c-2.8-1.4-5-3.4-6.6-5.9h-0.3
		V213.5z M101.3,171.9c0-2.3-0.3-4.4-1-6.5c-0.7-2-1.7-3.8-2.9-5.3c-1.3-1.5-2.9-2.7-4.7-3.6c-1.9-0.9-4-1.3-6.4-1.3
		c-2.2,0-4.3,0.4-6.1,1.3c-1.9,0.9-3.5,2-4.9,3.5c-1.4,1.5-2.4,3.3-3.2,5.3c-0.8,2-1.2,4.2-1.2,6.5c0,2.3,0.4,4.5,1.2,6.5
		c0.8,2,1.8,3.8,3.2,5.2c1.4,1.5,3,2.6,4.9,3.5c1.9,0.8,3.9,1.3,6.1,1.3c2.4,0,4.5-0.4,6.4-1.3c1.9-0.9,3.5-2,4.7-3.5
		c1.3-1.5,2.3-3.3,2.9-5.3C101,176.3,101.3,174.2,101.3,171.9z"
        />
        <path
          id="XMLID_2_"
          :fill="color"
          d="M132.6,161.1c-0.9-1.7-2.2-3.1-3.9-4.2c-1.7-1.1-3.7-1.6-5.9-1.6c-1,0-2,0.1-3.1,0.4
		c-1,0.2-1.9,0.6-2.7,1.1c-0.8,0.5-1.4,1.2-1.9,2c-0.5,0.8-0.7,1.8-0.7,2.9c0,1.9,0.8,3.4,2.3,4.4c1.5,1,4,1.9,7.3,2.6
		c4.7,1,8.2,2.4,10.5,4.3c2.2,1.9,3.4,4.5,3.4,7.7c0,2.3-0.4,4.3-1.3,5.9c-0.9,1.6-2,2.9-3.5,4c-1.4,1-3,1.8-4.8,2.2
		c-1.8,0.4-3.6,0.7-5.4,0.7c-3,0-5.9-0.6-8.8-1.9c-2.8-1.3-5.2-3.3-7-6.1l4.6-3.5c1,1.8,2.5,3.3,4.5,4.6c2,1.2,4.2,1.8,6.6,1.8
		c1.3,0,2.4-0.1,3.6-0.4c1.1-0.2,2.1-0.6,3-1.2s1.5-1.3,2-2.2c0.5-0.9,0.8-2,0.8-3.4c0-2.2-1-3.8-3-4.9c-2-1.1-4.9-2.1-8.7-3
		c-1.2-0.3-2.5-0.7-3.8-1.1c-1.3-0.4-2.6-1.1-3.7-1.9c-1.1-0.8-2.1-1.9-2.8-3.2c-0.7-1.3-1.1-2.9-1.1-4.8c0-2.1,0.4-3.9,1.2-5.4
		s1.9-2.7,3.2-3.7c1.3-1,2.9-1.7,4.6-2.2c1.7-0.5,3.5-0.7,5.3-0.7c2.8,0,5.5,0.6,8.1,1.9c2.6,1.3,4.6,3.1,5.9,5.4L132.6,161.1z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
	name: "Logo",
	props: {
		mode: {
			type: String,
			default: "horizontal"
		},
		color: {
			type: String,
			default: "#018c85"
		}
	}
};
</script>
